
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: #3498db;
  text-decoration: none;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #3498db 50%);
  background-size: 220%;
  transition: background 300ms ease-in-out;
}

.pdfEmbed {
  width:95%;
  height:1000px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.htmlDoc {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  border: none;
}

iframe.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

table, div.responsive-iframe
{
  margin: auto;
  border: 1px solid black;
}

td
{
  text-align: left;
  background-color: lightcyan;
}

td.songColumn{
  width: 150px;
  height:150px;
  text-align: center;
}

img.songImage{
  width: 75%;
  object-fit: contain;
}

#passworddiv{
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}
/*https://css-tricks.com/seamless-responsive-photo-grid/*/
#photos {
  /* Prevent vertical gaps */
  line-height: 0;

  -webkit-column-count: 5;
  -webkit-column-gap:   0px;
  -moz-column-count:    5;
  -moz-column-gap:      0px;
  column-count:         5;
  column-gap:           0px;
}

#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}

input::placeholder {
  color: gray;
}

input {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}


@media (max-width: 1200px) {
  #photos {
    -moz-column-count:    4;
    -webkit-column-count: 4;
    column-count:         4;
  }
}
@media (max-width: 1000px) {
  #photos {
    -moz-column-count:    3;
    -webkit-column-count: 3;
    column-count:         3;
  }
}
@media (max-width: 800px) {
  #photos {
    -moz-column-count:    2;
    -webkit-column-count: 2;
    column-count:         2;
  }
}
@media (max-width: 400px) {
  #photos {
    -moz-column-count:    1;
    -webkit-column-count: 1;
    column-count:         1;
  }
}

